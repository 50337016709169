import {
  BankOutlined,
  GlobalOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";
import countries from "../../../Data/countries.json";

const forms = [
  {
    name: <div className="text-[#6254BE] text-semi-bold text-2xl">Sign Up</div>,
    className: "flex items-center min-h-screen w-full",
    body: {
      className: "bg-white p-4 border rounded-lg p-8 w-[20%] mx-auto",
    },
    formdata: {
      className: "text-sm text-medium text-gray-500 rounded-md",
    },
    options: [
      {
        name: "",
        type: "input",
        form: {
          type: "text",
          name: "name",
          placeholder: "Name",
          className: "",
          size: "large",
          suffix: <UserOutlined className="text-gray-500" />,
          value: "",
          required: true,
        },
      },
      {
        name: "",
        type: "input",
        form: {
          type: "text",
          name: "company",
          placeholder: "Company",
          className: "",
          size: "large",
          suffix: <BankOutlined className="text-gray-500" />,
          value: "",
          required: true,
        },
      },
      {
        name: "",
        type: "input",
        form: {
          type: "text",
          name: "phone",
          placeholder: "Phone",
          className: "",
          size: "large",
          suffix: <PhoneOutlined className="text-gray-500" />,
          value: "",
          required: true,
        },
      },
      {
        name: "",
        type: "input",
        form: {
          type: "text",
          name: "email",
          placeholder: "Email",
          className: "",
          size: "large",
          suffix: <MailOutlined className="text-gray-500" />,
          value: "",
          required: true,
        },
      },
      {
        name: "",
        type: "password",
        form: {
          type: "text",
          name: "password",
          placeholder: "Password",
          className: "",
          size: "large",
          value: "",
          required: true,
        },
      },
      {
        name: "",
        type: "select",
        form: {
          size: "large",
          value: "Oman",
          name: "country",
          allowClear: false,
          showSearch: true,
          placeholder: "Oman",
          options: countries?.map((value, index) => ({
            value: value?.country,
            label: value?.country,
          })),
          suffixIcon: <GlobalOutlined className="text-gray-500" />,
          required: false,
        },
      },
    ],
    button: "",
  },
];

export default forms;
