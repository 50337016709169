import {
  DeleteOutlined,
  LeftOutlined,
  LoadingOutlined,
  MinusCircleOutlined,
  RightOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, DatePicker, Input, Select, Spin } from "antd";
import React, { createRef, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import products from "../../../Data/products.json";
import inventory from "../../../Data/inventory.json";
import customers from "../../../Data/customers.json";
import BarcodeButton from "../../../Componets/Buttons/barcode";
import { select_id, select_search } from "../../../Controllers/Global";
import moment from "moment";
import URL from "../../../Main/url";
import {
  get_inventory,
  get_inventory_barcode,
} from "../../../Controllers/Inventory/Batch";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import Modalcomponent from "../../../Componets/Modal";
import { create_customer } from "../../../Controllers/Customers";
import Loadercomponent from "../../../Componets/Loader";

const BillingPos = (props) => {
  const {
    loader,
    setLoader,
    options,
    setOptions,
    customer,
    setCustomer,
    details,
    setDetails,
    totals,
    setTotals,
    formdata,
    buttonLoader,
  } = props;

  const [isFocused, setIsFocused] = useState(false);

  const [search, setSearch] = useState("");

  const [inventoryoption, setInventoryOption] = useState();

  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState();
  const [selcectedModalData, setSelcectedModalData] = useState();

  const [customerName, setCustomerName] = useState();
  const [customerPhone, setCustomerPhone] = useState();
  const [customerOptions, setCustomerOptions] = useState();

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalCount: 0,
    totalPages: 0,
  });

  const handleCustomerSearch = async (e) => {
    const select = {
      api: "get-all-customers",
      search: e,
      status: 1,
    };

    const search = await select_search(select);

    if (search?.status) {
      let options = [];
      search?.data?.data?.map((value, index) => {
        options?.push({
          value: value?._id,
          label: (
            <div>
              {value?.name}
              &nbsp;{value?.phone ? "(" + value?.phone + ")" : ""}
            </div>
          ),
        });
      });

      setCustomerOptions(options);
    } else {
      toast?.error(search?.message);
    }
  };

  const handleCustomer = (e) => {
    if (e?.target?.name == "name") {
      setCustomerName(e?.target?.value);
    } else if (e?.target?.name == "phone") {
      setCustomerPhone(e?.target?.value);
    }
  };

  const addCustomer = async (e) => {
    if (customerPhone) {
      let params = {
        name: customerName,
        phone: customerPhone,
        status: 1,
      };

      const addcustomer = await create_customer(params);

      if (addcustomer?.status) {
        toast?.success(addcustomer?.message);
        setCustomerName("");
        setCustomerPhone("");
      } else {
        toast?.error(addcustomer?.message);
      }
    } else {
      toast?.error("Phone Required");
    }
  };

  const handleNext = () => {
    let currentPage = parseInt(pagination?.currentPage) + 1;

    if (currentPage <= parseInt(pagination?.totalPages)) {
      let data = {
        ...pagination,
        currentPage: currentPage,
      };

      handleSearch(search, data);
    }
  };

  const handlePrevious = () => {
    let currentPage = parseInt(pagination?.currentPage) - 1;

    if (currentPage > 0) {
      let data = {
        ...pagination,
        currentPage: currentPage,
      };

      handleSearch(search, data);
    }
  };

  const handleSelectSearch = async (e, ind) => {
    const select = {
      api: "get-all-inventories",
      search: e,
      status: 1,
    };
    const search = await select_search(select);

    if (search?.status) {
      let options = [];
      search?.data?.data?.map((value, index) => {
        options?.push({ value: value?._id, label: value?.product?.name });
      });

      setInventoryOption(options);
    } else {
      toast?.error(search?.message);
    }
  };

  const handleSearch = async (e, page) => {
    const select = {
      api: "get-all-inventories",
      search: e,
      status: 1,
      sort: 2,
      page: page?.currentPage,
    };

    setSearch(e);

    const search = await select_search(select);

    if (search?.status) {
      let options = [];
      search?.data?.data?.map((value, index) => {
        options?.push({
          value: value?._id,
          image: value?.product?.image,
          name: value?.product?.name,
          stock: value?.stock,
          number: `#BATCH-${value?.number}`,
          expiry_date: value?.expiry_date
            ? moment?.(value?.expiry_date)?.format("DD-MM-YYYY")
            : "N/A",
          sale_price: value?.sale_price,
        });
      });

      setPagination({
        currentPage: search?.data?.currentPage,
        totalCount: search?.data?.totalCount,
        totalPages: search?.data?.totalPages,
      });

      setOptions(options);

      setLoader(false);
    } else {
      setLoader(false);
      toast?.error(search?.message);
    }
  };

  const handleDescriptionBarcode = async (e) => {
    let data = [...details];

    if (isFocused) {
      if (e) {
        let params = {
          barcode: e,
        };

        const inventory = await get_inventory_barcode(params);

        if (inventory?.status) {
          if (
            inventory?.data?.expiry_date &&
            new Date(new Date(inventory?.data?.expiry_date).getTime()) <
              new Date()
          ) {
            toast?.error(
              `Inventory expired on ${moment?.(
                inventory?.data?.expiry_date
              )?.format("DD-MM-YYYY")}`
            );
          } else {
            if (inventory?.data?.inventory_unit_details?.length > 0) {
              setOpen(true);

              let modaldata = [
                {
                  ...inventory?.data,
                  name: inventory?.data?.product?.unit?.name,
                },
              ];

              inventory?.data?.inventory_unit_details?.map((v, i) => {
                modaldata?.push(v);
              });

              setModalData(modaldata);

              setSelcectedModalData({ ...inventory, index: details?.length });
            } else {
              if (inventory?.stock > 1) {
                let description_ids = [];

                data?.map((v, i) => {
                  description_ids?.push(v?.description);
                });

                console.log(description_ids);

                if (!description_ids?.includes(inventory?.data?._id)) {
                  let inventory_description = inventory?.data?._id;
                  let inventory_name = inventory?.data?.product?.name;
                  let inventory_batch = inventory?.data?.number;
                  let inventory_expiry_date = inventory?.data?.expiry_date;
                  let inventory_unit = inventory?.data?._id;
                  let inventory_unit_name =
                    inventory?.data?.product?.unit?.name;
                  let inventory_sale_price = inventory?.data?.sale_price;
                  let inventory_stock = inventory?.data?.stock;
                  let inventory_tax = inventory?.data?.tax;

                  let inventory_unit_details = [];
                  if (inventory?.data?.inventory_unit_details?.length > 0) {
                    inventory?.data?.inventory_unit_details?.map(
                      (value, index) => {
                        inventory_unit_details?.push({
                          ...value,
                          name: value?.name?.name,
                          price_per_unit: 0,
                          sale_price: "",
                          unit_quantity: 0,
                          unit_delivered: 0,
                        });
                      }
                    );
                  }

                  let price_sale = parseFloat(inventory_sale_price || 0);
                  let price_tax =
                    parseFloat(price_sale || 0) *
                    (parseFloat(inventory_tax || 0) / 100);
                  let price_total =
                    parseFloat(price_sale || 0) + parseFloat(price_tax || 0);

                  data?.push({
                    description: inventory_description,
                    name: inventory_name,
                    batch: inventory_batch,
                    expiry_date: inventory_expiry_date,
                    unit: inventory_unit,
                    unit_name: inventory_unit_name,
                    sale_price: price_sale,
                    stock: inventory_stock,
                    tax: inventory_tax,
                    quantity: 1,
                    delivered: 1,
                    tax_amount: price_tax,
                    total: price_total,
                  });

                  let subtotal =
                    data?.reduce(
                      (acc, currentValue) =>
                        parseFloat(acc) + parseFloat(currentValue?.total),
                      0
                    ) -
                    data?.reduce(
                      (acc, currentValue) =>
                        parseFloat(acc) + parseFloat(currentValue?.tax_amount),
                      0
                    );
                  let taxamount = data?.reduce(
                    (acc, currentValue) =>
                      parseFloat(acc) + parseFloat(currentValue?.tax_amount),
                    0
                  );
                  let grandtotal = data?.reduce(
                    (acc, currentValue) =>
                      parseFloat(acc) + parseFloat(currentValue?.total),
                    0
                  );

                  totals.subtotal = subtotal;
                  totals.taxamount = taxamount;
                  totals.delivery_status = 2;
                  totals.delivery_date = new Date();
                  totals.payment_status = 2;
                  totals.payment_types = ["Cash"];
                  totals.payments = {
                    Cash: "",
                    Cheque: "",
                    "Debit card": "",
                    "Credit card": "",
                    "Bank transfer": "",
                    "Online payment": "",
                  };
                  totals.total = grandtotal;

                  setDetails(data);
                  setTotals({ ...totals });
                } else {
                  let selected_index = description_ids?.indexOf(
                    inventory?.data?._id
                  );

                  if (
                    parseFloat(data[selected_index].quantity || 0) <
                    parseFloat(data[selected_index].stock || 0)
                  ) {
                    let quantity = data[selected_index].quantity
                      ? data[selected_index].quantity
                      : 0;
                    let delivered = data[selected_index].delivered
                      ? data[selected_index].delivered
                      : 0;

                    let total_quantity = parseFloat(quantity) + parseFloat(1);

                    data[selected_index].quantity = total_quantity;
                    data[selected_index].delivered = total_quantity;

                    data[selected_index].tax_amount = data[selected_index].tax
                      ? parseFloat(data[selected_index].sale_price || 0) *
                        parseFloat(total_quantity || 0) *
                        (parseFloat(data[selected_index].tax || 0) / 100)
                      : 0;
                    data[selected_index].total =
                      parseFloat(data[selected_index].sale_price || 0) *
                        parseFloat(total_quantity) +
                      (data[selected_index].tax
                        ? parseFloat(data[selected_index].sale_price || 0) *
                          parseFloat(total_quantity || 0) *
                          (parseFloat(data[selected_index].tax || 0) / 100)
                        : 0);

                    let subtotal =
                      data?.reduce(
                        (acc, currentValue) =>
                          parseFloat(acc) + parseFloat(currentValue?.total),
                        0
                      ) -
                      data?.reduce(
                        (acc, currentValue) =>
                          parseFloat(acc) +
                          parseFloat(currentValue?.tax_amount),
                        0
                      );
                    let taxamount = data?.reduce(
                      (acc, currentValue) =>
                        parseFloat(acc) + parseFloat(currentValue?.tax_amount),
                      0
                    );
                    let grandtotal = data?.reduce(
                      (acc, currentValue) =>
                        parseFloat(acc) + parseFloat(currentValue?.total),
                      0
                    );

                    totals.subtotal = subtotal;
                    totals.taxamount = taxamount;
                    totals.delivery_status = 2;
                    totals.delivery_date = new Date();
                    totals.payment_status = 2;
                    totals.payment_types = ["Cash"];
                    totals.payments = {
                      Cash: "",
                      Cheque: "",
                      "Debit card": "",
                      "Credit card": "",
                      "Bank transfer": "",
                      "Online payment": "",
                    };
                    totals.total = grandtotal;

                    setDetails(data);
                    setTotals({ ...totals });
                  } else {
                    toast?.error(`Stock only ${data[selected_index].stock}`);
                  }
                }
              } else {
                toast?.error(`Stock only ${inventory?.data?.stock}`);
              }
            }
          }
        } else {
          toast?.error("Inventory not found");
        }
      }
    }
  };

  const handleModal = (modaldata) => {
    let data = [...details];

    let inventory = selcectedModalData;
    let index = selcectedModalData?.index;

    let inventory_description = null;
    let inventory_name = "";
    let inventory_batch = "";
    let inventory_expiry_date = "";
    let inventory_price_per_unit = "";
    let inventory_unit = "";
    let inventory_unit_name = "";
    let inventory_sale_price = 0;
    let inventory_stock = 0;
    let inventory_tax = 0;
    let inventory_conversion = 0;
    let inventory_unit_details = [];

    let description_ids = [];

    data?.map((v, i) => {
      description_ids?.push(v?.unit);
    });

    if (!description_ids?.includes(modaldata)) {
      if (inventory?.data?._id == modaldata) {
        inventory_description = inventory?.data?._id;
        inventory_name = inventory?.data?.product?.name;
        inventory_batch = inventory?.data?.number;
        inventory_expiry_date = inventory?.data?.expiry_date;
        inventory_price_per_unit = inventory?.data?.price_per_unit;
        inventory_unit = modaldata;
        inventory_unit_name = inventory?.data?.product?.unit?.name;
        inventory_sale_price = inventory?.data?.sale_price;
        inventory_stock = inventory?.data?.stock;
        inventory_tax = inventory?.data?.tax;

        if (inventory?.data?.inventory_unit_details?.length > 0) {
          inventory?.data?.inventory_unit_details?.map((value, index) => {
            inventory_unit_details?.push({
              ...value,
              name: value?.name,
              purchase_price: value?.purchase_price,
              price_per_unit: value?.price_per_unit,
              sale_price: value?.sale_price,
              stock: value?.stock,
              unit_quantity: 0,
              unit_delivered: 0,
            });
          });
        }
      } else {
        let selected_unit = "";
        let unit_ids = [];

        if (inventory?.data?.inventory_unit_details?.length > 0) {
          inventory?.data?.inventory_unit_details?.map((value, index) => {
            unit_ids?.push(value?._id);
          });
        }

        if (unit_ids?.includes(modaldata)) {
          selected_unit =
            inventory?.data?.inventory_unit_details?.[
              unit_ids?.indexOf(modaldata)
            ];
        }

        inventory_description = inventory?.data?._id;
        inventory_name = inventory?.data?.product?.name;
        inventory_batch = inventory?.data?.number;
        inventory_expiry_date = inventory?.data?.expiry_date;
        inventory_unit = modaldata;
        inventory_unit_name = selected_unit?.name;
        inventory_sale_price = selected_unit?.sale_price;
        inventory_stock = selected_unit?.stock;
        inventory_tax = inventory?.data?.tax;
        inventory_conversion = selected_unit?.conversion;
      }

      if (inventory_stock >= 1) {
        let price_sale = parseFloat(inventory_sale_price || 0);
        let price_tax =
          parseFloat(price_sale || 0) * (parseFloat(inventory_tax || 0) / 100);
        let price_total =
          parseFloat(price_sale || 0) + parseFloat(price_tax || 0);

        data?.push({
          description: inventory_description,
          name: inventory_name,
          batch: inventory_batch,
          expiry_date: inventory_expiry_date,
          unit: inventory_unit,
          unit_name: inventory_unit_name,
          sale_price: price_sale,
          stock: inventory_stock,
          tax: inventory_tax,
          conversion: inventory_conversion,
          quantity: 1,
          delivered: 1,
          tax_amount: price_tax,
          total: price_total,
          unit_details_options: inventory_unit_details,
        });

        let subtotal =
          data?.reduce(
            (acc, currentValue) =>
              parseFloat(acc) + parseFloat(currentValue?.total),
            0
          ) -
          data?.reduce(
            (acc, currentValue) =>
              parseFloat(acc) + parseFloat(currentValue?.tax_amount),
            0
          );
        let taxamount = data?.reduce(
          (acc, currentValue) =>
            parseFloat(acc) + parseFloat(currentValue?.tax_amount),
          0
        );
        let grandtotal = data?.reduce(
          (acc, currentValue) =>
            parseFloat(acc) + parseFloat(currentValue?.total),
          0
        );

        totals.subtotal = subtotal;
        totals.taxamount = taxamount;
        totals.delivery_status = 2;
        totals.delivery_date = new Date();
        totals.payment_status = 2;
        totals.payment_types = ["Cash"];
        totals.payments = {
          Cash: "",
          Cheque: "",
          "Debit card": "",
          "Credit card": "",
          "Bank transfer": "",
          "Online payment": "",
        };
        totals.total = grandtotal;

        setDetails(data);
        setTotals({ ...totals });
        setModalData();
        setSelcectedModalData();
      } else {
        toast?.error(`Stock only ${inventory_stock}`);
        setModalData();
        setSelcectedModalData();
      }
    } else {
      let selected_index = description_ids?.indexOf(modaldata);

      if (
        parseFloat(data[selected_index].quantity || 0) <
        parseFloat(Math.floor(data[selected_index].stock) || 0)
      ) {
        if (
          data?.[selected_index]?.conversion > 0 &&
          data?.[selected_index]?.quantity + parseFloat(1) >=
            data?.[selected_index]?.conversion
        ) {
          toast?.error(
            `Quantity exceeding ${data?.[selected_index]?.conversion}`
          );
        } else {
          let quantity = data[selected_index].quantity
            ? data[selected_index].quantity
            : 0;
          let delivered = data[selected_index].delivered
            ? data[selected_index].delivered
            : 0;

          let total_quantity = parseFloat(quantity) + parseFloat(1);

          data[selected_index].quantity = total_quantity;
          data[selected_index].delivered = total_quantity;

          data[selected_index].tax_amount = data[selected_index].tax
            ? parseFloat(data[selected_index].sale_price || 0) *
              parseFloat(total_quantity || 0) *
              (parseFloat(data[selected_index].tax || 0) / 100)
            : 0;
          data[selected_index].total =
            parseFloat(data[selected_index].sale_price || 0) *
              parseFloat(total_quantity) +
            (data[selected_index].tax
              ? parseFloat(data[selected_index].sale_price || 0) *
                parseFloat(total_quantity || 0) *
                (parseFloat(data[selected_index].tax || 0) / 100)
              : 0);

          let subtotal =
            data?.reduce(
              (acc, currentValue) =>
                parseFloat(acc) + parseFloat(currentValue?.total),
              0
            ) -
            data?.reduce(
              (acc, currentValue) =>
                parseFloat(acc) + parseFloat(currentValue?.tax_amount),
              0
            );
          let taxamount = data?.reduce(
            (acc, currentValue) =>
              parseFloat(acc) + parseFloat(currentValue?.tax_amount),
            0
          );
          let grandtotal = data?.reduce(
            (acc, currentValue) =>
              parseFloat(acc) + parseFloat(currentValue?.total),
            0
          );

          totals.subtotal = subtotal;
          totals.taxamount = taxamount;
          totals.delivery_status = 2;
          totals.delivery_date = new Date();
          totals.payment_status = 2;
          totals.payment_types = ["Cash"];
          totals.payments = {
            Cash: "",
            Cheque: "",
            "Debit card": "",
            "Credit card": "",
            "Bank transfer": "",
            "Online payment": "",
          };
          totals.total = grandtotal;

          setDetails(data);
          setTotals({ ...totals });
        }
      } else {
        toast?.error(`Stock only ${data[selected_index].stock}`);
      }
    }
  };

  const handleDescription = async (e, index) => {
    let data = [...details];

    if (e) {
      let params = {
        id: e,
      };
      const inventory = await get_inventory(params);

      if (inventory?.status) {
        if (
          inventory?.data?.expiry_date &&
          new Date(new Date(inventory?.data?.expiry_date).getTime()) <
            new Date()
        ) {
          toast?.error(
            `Inventory expired on ${moment?.(
              inventory?.data?.expiry_date
            )?.format("DD-MM-YYYY")}`
          );
        } else {
          if (inventory?.data?.inventory_unit_details?.length > 0) {
            setOpen(true);

            let modaldata = [
              {
                ...inventory?.data,
                name: inventory?.data?.product?.unit?.name,
              },
            ];

            inventory?.data?.inventory_unit_details?.map((v, i) => {
              modaldata?.push(v);
            });

            setModalData(modaldata);

            setSelcectedModalData({ ...inventory, index: index });
          } else {
            if (inventory?.data?.stock >= 1) {
              let description_ids = [];

              data?.map((v, i) => {
                description_ids?.push(v?.description);
              });

              if (!description_ids?.includes(e)) {
                let inventory_description = inventory?.data?._id;
                let inventory_name = inventory?.data?.product?.name;
                let inventory_batch = inventory?.data?.number;
                let inventory_expiry_date = inventory?.data?.expiry_date;
                let inventory_unit = inventory?.data?._id;
                let inventory_unit_name = inventory?.data?.product?.unit?.name;
                let inventory_sale_price = inventory?.data?.sale_price;
                let inventory_stock = inventory?.data?.stock;
                let inventory_tax = inventory?.data?.tax;

                let inventory_unit_details = [];
                if (inventory?.data?.inventory_unit_details?.length > 0) {
                  inventory?.data?.inventory_unit_details?.map(
                    (value, index) => {
                      inventory_unit_details?.push({
                        ...value,
                        name: value?.name?.name,
                        price_per_unit: 0,
                        sale_price: "",
                        unit_quantity: 0,
                        unit_delivered: 0,
                      });
                    }
                  );
                }

                let price_sale = parseFloat(inventory_sale_price || 0);
                let price_tax =
                  parseFloat(price_sale || 0) *
                  (parseFloat(inventory_tax || 0) / 100);
                let price_total =
                  parseFloat(price_sale || 0) + parseFloat(price_tax || 0);

                data?.push({
                  description: inventory_description,
                  name: inventory_name,
                  batch: inventory_batch,
                  expiry_date: inventory_expiry_date,
                  unit: inventory_unit,
                  unit_name: inventory_unit_name,
                  sale_price: price_sale,
                  stock: inventory_stock,
                  tax: inventory_tax,
                  quantity: 1,
                  delivered: 1,
                  tax_amount: price_tax,
                  total: price_total,
                  unit_details_options: inventory_unit_details,
                });

                let subtotal =
                  data?.reduce(
                    (acc, currentValue) =>
                      parseFloat(acc) + parseFloat(currentValue?.total),
                    0
                  ) -
                  data?.reduce(
                    (acc, currentValue) =>
                      parseFloat(acc) + parseFloat(currentValue?.tax_amount),
                    0
                  );
                let taxamount = data?.reduce(
                  (acc, currentValue) =>
                    parseFloat(acc) + parseFloat(currentValue?.tax_amount),
                  0
                );
                let grandtotal = data?.reduce(
                  (acc, currentValue) =>
                    parseFloat(acc) + parseFloat(currentValue?.total),
                  0
                );

                totals.subtotal = subtotal;
                totals.taxamount = taxamount;
                totals.delivery_status = 2;
                totals.delivery_date = new Date();
                totals.payment_status = 2;
                totals.payment_types = ["Cash"];
                totals.payments = {
                  Cash: "",
                  Cheque: "",
                  "Debit card": "",
                  "Credit card": "",
                  "Bank transfer": "",
                  "Online payment": "",
                };
                totals.total = grandtotal;

                setDetails(data);
                setTotals({ ...totals });
              } else {
                let selected_index = description_ids?.indexOf(e);

                if (
                  parseFloat(data[selected_index].quantity || 0) <
                  parseFloat(data[selected_index].stock || 0)
                ) {
                  let quantity = data[selected_index].quantity
                    ? data[selected_index].quantity
                    : 0;
                  let delivered = data[selected_index].delivered
                    ? data[selected_index].delivered
                    : 0;

                  let total_quantity = parseFloat(quantity) + parseFloat(1);

                  data[selected_index].quantity = total_quantity;
                  data[selected_index].delivered = total_quantity;

                  data[selected_index].tax_amount = data[selected_index].tax
                    ? parseFloat(data[selected_index].sale_price || 0) *
                      parseFloat(total_quantity || 0) *
                      (parseFloat(data[selected_index].tax || 0) / 100)
                    : 0;
                  data[selected_index].total =
                    parseFloat(data[selected_index].sale_price || 0) *
                      parseFloat(total_quantity) +
                    (data[selected_index].tax
                      ? parseFloat(data[selected_index].sale_price || 0) *
                        parseFloat(total_quantity || 0) *
                        (parseFloat(data[selected_index].tax || 0) / 100)
                      : 0);

                  let subtotal =
                    data?.reduce(
                      (acc, currentValue) =>
                        parseFloat(acc) + parseFloat(currentValue?.total),
                      0
                    ) -
                    data?.reduce(
                      (acc, currentValue) =>
                        parseFloat(acc) + parseFloat(currentValue?.tax_amount),
                      0
                    );
                  let taxamount = data?.reduce(
                    (acc, currentValue) =>
                      parseFloat(acc) + parseFloat(currentValue?.tax_amount),
                    0
                  );
                  let grandtotal = data?.reduce(
                    (acc, currentValue) =>
                      parseFloat(acc) + parseFloat(currentValue?.total),
                    0
                  );

                  totals.subtotal = subtotal;
                  totals.taxamount = taxamount;
                  totals.delivery_status = 2;
                  totals.delivery_date = new Date();
                  totals.payment_status = 2;
                  totals.payment_types = ["Cash"];
                  totals.payments = {
                    Cash: "",
                    Cheque: "",
                    "Debit card": "",
                    "Credit card": "",
                    "Bank transfer": "",
                    "Online payment": "",
                  };
                  totals.total = grandtotal;

                  setDetails(data);
                  setTotals({ ...totals });
                } else {
                  toast?.error(`Stock only ${data[selected_index].stock}`);
                }
              }
            } else {
              toast?.error(`Stock only ${inventory?.data?.stock}`);
            }
          }
        }
      } else {
        toast?.error(`Stock only ${inventory?.data?.stock}`);
      }
    } else {
      data[index].id = "";
      data[index].description = null;
      data[index].name = "";
      data[index].batch = "";
      data[index].expiry_date = "";
      data[index].unit = "";
      data[index].unit_name = "";
      data[index].unit_details_options = null;
      data[index].quantity = "";
      data[index].conversion = 0;
      data[index].purchase = "";
      data[index].sale_price = "";
      data[index].purchase_options = null;
      data[index].delivered = "";
      data[index].tax = 0;
      data[index].free = "";
      data[index].barcode = "";
      data[index].price_per_unit = "";
      data[index].sale_price = "";
      data[index].expiry_date = "";
      data[index].tax_amount = "0.000";
      data[index].total = 0;
      setDetails(data);
    }
  };

  const handlePurchase = (e, index, key) => {
    if (e) {
      let event = {
        target: {
          name: "sale_price",
          value: JSON?.parse(e)?.sale_price,
        },
      };

      handleTotal(event, index, key);
      handleTotal(JSON?.parse(e)?.tax, index, "tax");
    }
  };

  const handleDelivered = (e, index) => {
    console.log(e);
    if (e?.target?.value >= 0) {
      let data = [...details];

      let data_quantity = data[index]?.quantity
        ? parseFloat(data[index]?.quantity)
        : 0;
      let data_delivered = e?.target?.value ? parseFloat(e?.target?.value) : 0;

      if (data_delivered <= data_quantity) {
        data[index][e?.target?.name] = e?.target?.value;
        setDetails(data);
      } else {
        toast?.error(`Quantity only ${data_quantity}`);
      }
    }
  };

  const handleTotal = (e, index, key) => {
    let name = key ? key : e?.target?.name;
    let value = key && e ? e : !key && e?.target?.value ? e?.target?.value : "";

    if (value >= 0) {
      let data = [...details];

      if (value <= data[index]?.stock) {
        let data_sale_price = data[index]?.sale_price
          ? data[index]?.sale_price
          : 0;
        let data_quantity = data[index]?.quantity ? data[index]?.quantity : 0;
        let data_deliverd = data[index]?.delivered ? data[index]?.delivered : 0;
        let data_free = data[index]?.free ? data[index]?.free : 0;
        let data_tax = data[index]?.tax ? data[index]?.tax : 0;
        let data_conversion = data[index].conversion
          ? data[index].conversion
          : 0;

        let sale_price = name === "sale_price" ? value : data_sale_price;
        let quantity = name === "quantity" ? value : data_quantity;
        let delivered = name === "quantity" ? value : data_deliverd;
        let free = name === "free" ? value : data_free;
        let tax = name === "tax" ? value : data_tax;

        let tax_amount = tax
          ? parseFloat(sale_price) *
            parseFloat(quantity ? quantity : 0) *
            (parseFloat(tax) / 100)
          : 0;

        let total =
          parseFloat(sale_price) * parseFloat(quantity ? quantity : 0) +
          parseFloat(tax_amount);

        let price_per_unit =
          parseFloat(total ? total : 0) /
          (parseFloat(quantity ? quantity : 0) + parseFloat(free ? free : 0));

        let unit_details_options = [];
        if (data?.[index]?.unit_details_options?.length > 0) {
          data?.[index]?.unit_details_options?.map((v, i) => {
            let total_quantity =
              parseFloat(quantity || 0) + parseFloat(free || 0);

            unit_details_options?.push({
              ...v,
              //price_per_unit
              price_per_unit: price_per_unit
                ? parseFloat(price_per_unit || 0) /
                  parseFloat(v?.conversion || 0)
                : 0,
              //unit_quantity
              unit_quantity: quantity
                ? parseFloat(v?.conversion || 0) *
                  parseFloat(total_quantity || 0)
                : 0,
              //unit_deliverd
              unit_delivered: delivered
                ? parseFloat(v?.conversion || 0) *
                  parseFloat(total_quantity || 0)
                : 0,
            });
          });
        }

        if (
          data_conversion > 0 &&
          parseFloat(quantity) + parseFloat(free) >= data_conversion
        ) {
          toast?.error(`Quantiy exceeding ${data[index].conversion}`);
        } else {
          data[index][name] = value;
          data[index].unit_details_options = unit_details_options;
          data[index].delivered = delivered ? delivered : "";
          data[index].tax = tax ? tax : 0;
          data[index].total = total ? total : 0;
          data[index].tax_amount = tax_amount ? tax_amount : "0.000";
          data[index].price_per_unit = price_per_unit ? price_per_unit : 0;

          let subtotal =
            data?.reduce(
              (acc, currentValue) =>
                parseFloat(acc) + parseFloat(currentValue?.total),
              0
            ) -
            data?.reduce(
              (acc, currentValue) =>
                parseFloat(acc) + parseFloat(currentValue?.tax_amount),
              0
            );

          let taxamount = data?.reduce(
            (acc, currentValue) =>
              parseFloat(acc) + parseFloat(currentValue?.tax_amount),
            0
          );

          let grandtotal = data?.reduce(
            (acc, currentValue) =>
              parseFloat(acc) + parseFloat(currentValue?.total),
            0
          );

          totals.subtotal = subtotal;
          totals.taxamount = taxamount;
          totals.delivery_status = 0;
          totals.delivery_date = "";
          totals.payment_status = 0;
          totals.payment_types = null;
          totals.payments = {
            Cash: "",
            Cheque: "",
            "Debit card": "",
            "Credit card": "",
            "Bank transfer": "",
            "Online payment": "",
          };
          totals.total = grandtotal;

          setDetails(data);
          setTotals({ ...totals });
        }
      } else {
        toast?.error(`Stock only ${data[index]?.stock}`);
      }
    }
  };

  const handleRemove = (index) => {
    let data = [...details];

    let name = "quantity";
    let value = 0;

    if (value <= data[index]?.stock) {
      let data_sale_price = data[index]?.sale_price
        ? data[index]?.sale_price
        : 0;
      let data_quantity = data[index]?.quantity ? data[index]?.quantity : 0;
      let data_deliverd = data[index]?.delivered ? data[index]?.delivered : 0;
      let data_free = data[index]?.free ? data[index]?.free : 0;
      let data_tax = data[index]?.tax ? data[index]?.tax : 0;
      let data_conversion = data[index].conversion ? data[index].conversion : 0;

      let sale_price = name === "sale_price" ? value : data_sale_price;
      let quantity = name === "quantity" ? value : data_quantity;
      let delivered = name === "quantity" ? value : data_deliverd;
      let free = name === "free" ? value : data_free;
      let tax = name === "tax" ? value : data_tax;

      let tax_amount = tax
        ? parseFloat(sale_price) *
          parseFloat(quantity ? quantity : 0) *
          (parseFloat(tax) / 100)
        : 0;

      let total =
        parseFloat(sale_price) * parseFloat(quantity ? quantity : 0) +
        parseFloat(tax_amount);

      let price_per_unit =
        parseFloat(total ? total : 0) /
        (parseFloat(quantity ? quantity : 0) + parseFloat(free ? free : 0));

      let unit_details_options = [];
      if (data?.[index]?.unit_details_options?.length > 0) {
        data?.[index]?.unit_details_options?.map((v, i) => {
          let total_quantity =
            parseFloat(quantity || 0) + parseFloat(free || 0);

          unit_details_options?.push({
            ...v,
            //price_per_unit
            price_per_unit: price_per_unit
              ? parseFloat(price_per_unit || 0) / parseFloat(v?.conversion || 0)
              : 0,
            //unit_quantity
            unit_quantity: quantity
              ? parseFloat(v?.conversion || 0) * parseFloat(total_quantity || 0)
              : 0,
            //unit_deliverd
            unit_delivered: delivered
              ? parseFloat(v?.conversion || 0) * parseFloat(total_quantity || 0)
              : 0,
          });
        });
      }

      if (
        data_conversion > 0 &&
        parseFloat(quantity) + parseFloat(free) >= data_conversion
      ) {
        toast?.error(`Quantiy exceeding ${data[index].conversion}`);
      } else {
        data[index][name] = value;
        data[index].unit_details_options = unit_details_options;
        data[index].delivered = delivered ? delivered : "";
        data[index].tax = tax ? tax : 0;
        data[index].total = total ? total : 0;
        data[index].tax_amount = tax_amount ? tax_amount : "0.000";
        data[index].price_per_unit = price_per_unit ? price_per_unit : 0;

        let subtotal =
          data?.reduce(
            (acc, currentValue) =>
              parseFloat(acc) + parseFloat(currentValue?.total),
            0
          ) -
          data?.reduce(
            (acc, currentValue) =>
              parseFloat(acc) + parseFloat(currentValue?.tax_amount),
            0
          );

        let taxamount = data?.reduce(
          (acc, currentValue) =>
            parseFloat(acc) + parseFloat(currentValue?.tax_amount),
          0
        );

        let grandtotal = data?.reduce(
          (acc, currentValue) =>
            parseFloat(acc) + parseFloat(currentValue?.total),
          0
        );

        totals.discount = "";
        totals.delivery = "";
        totals.subtotal = subtotal;
        totals.taxamount = taxamount;
        totals.delivery_status = 2;
        totals.delivery_date = new Date();
        totals.payment_status = 2;
        totals.payment_types = ["Cash"];
        totals.payments = {
          Cash: "",
          Cheque: "",
          "Debit card": "",
          "Credit card": "",
          "Bank transfer": "",
          "Online payment": "",
        };
        totals.total = grandtotal;

        data?.splice(index, 1);
        setDetails(data);
        setTotals({ ...totals });
      }
    } else {
      toast?.error(`Stock only ${data[index]?.stock}`);
    }
  };

  const handleAdd = (e) => {
    if (e) {
      setDetails((prevDetails) => [
        ...prevDetails,
        {
          id: "",
          description: null,
          unit: "",
          sale_price: "",
          quantity: "",
          delivered: "",
          free: "",
          tax: 0,
          total: 0,
        },
      ]);

      handleDescription(e, details?.length + 1);
    }
  };

  const handleDelivery = (e) => {
    if (e?.target?.value >= 0) {
      let total_amount = parseFloat(
        details?.reduce(
          (acc, currentValue) =>
            parseFloat(acc) + parseFloat(currentValue?.total),
          0
        )
      );

      let total =
        parseFloat(total_amount ? total_amount : 0) +
        parseFloat(e?.target?.value ? e?.target?.value : 0) -
        parseFloat(totals?.discount ? totals?.discount : 0);

      totals.delivery = e?.target?.value;
      totals.total = total;

      setTotals({ ...totals });
    }
  };

  const handleDiscount = (e) => {
    if (e?.target?.value >= 0) {
      let total_amount = parseFloat(
        details?.reduce(
          (acc, currentValue) =>
            parseFloat(acc) + parseFloat(currentValue?.total),
          0
        )
      );

      let total =
        parseFloat(total_amount ? total_amount : 0) +
        parseFloat(totals?.delivery ? totals?.delivery : 0) -
        parseFloat(e?.target?.value ? e?.target?.value : 0);

      if (e?.target?.value <= total_amount) {
        totals.discount = e?.target?.value;
        totals.total = total;
      } else {
        toast?.error(`Discount exceeding`);
      }

      setTotals({ ...totals });
    }
  };

  const handleDeliveryStatus = (e) => {
    totals.delivery_status = e;

    if (!e) {
      totals.delivery_date = "";
    } else if (e == 1) {
      totals.delivery_date = "";
    }

    setTotals({ ...totals });
  };

  const handleDeliveryDate = (e) => {
    totals.delivery_date = e?.$d;
    setTotals({ ...totals });
  };

  const handlePaymentStatus = (e) => {
    totals.payment_status = e;

    if (!e) {
      totals.payment_types = null;
    }
    setTotals({ ...totals });
  };

  const handlePaymentTypes = (e) => {
    totals?.payment_types?.map((v, i) => {
      if (!e?.includes(v)) {
        totals.payments[v] = "";
      }
    });

    totals.payment_types = e;

    setTotals({ ...totals });
  };

  const handlePayments = (e) => {
    let name = e?.target?.name;
    let value = e?.target?.value ? e?.target?.value : "";

    if (e?.target?.value >= 0) {
      let data_Cash = totals?.payments?.Cash?.amount
        ? totals?.payments?.Cash?.amount
        : 0;
      let data_Cheque = totals?.payments?.Cheque?.amount
        ? totals?.payments?.Cheque?.amount
        : 0;
      let data_Debitcard = totals?.payments?.["Debit card"]?.amount
        ? totals?.payments?.["Debit card"]?.amount
        : 0;
      let data_Creditcard = totals?.payments?.["Credit card"]?.amount
        ? totals?.payments?.["Credit card"]?.amount
        : 0;
      let data_Banktransfer = totals?.payments?.["Bank transfer"]?.amount
        ? totals?.payments?.["Bank transfer"]?.amount
        : 0;
      let data_Onlinepayment = totals?.payments?.["Online payment"]?.amount
        ? totals?.payments?.["Online payment"]?.amount
        : 0;

      let Cash = name === "Cash" ? value : data_Cash;
      let Cheque = name === "Cheque" ? value : data_Cheque;
      let Debitcard = name === "Debit card" ? value : data_Debitcard;
      let Creditcard = name === "Credit card" ? value : data_Creditcard;
      let Banktransfer = name === "Bank transfer" ? value : data_Banktransfer;
      let Onlinepayment =
        name === "Online payment" ? value : data_Onlinepayment;

      let total =
        parseFloat(Cash) +
        parseFloat(Cheque) +
        parseFloat(Debitcard) +
        parseFloat(Creditcard) +
        parseFloat(Banktransfer) +
        parseFloat(Onlinepayment);

      let payment_total = total ? parseFloat(total) : 0;
      let grand_total = totals?.total ? parseFloat(totals?.total) : 0;

      if (payment_total <= grand_total) {
        totals.payments[name] = { ...totals.payments[name], amount: value };
        setTotals({ ...totals });
      } else {
        let previous_total =
          parseFloat(data_Cash) +
          parseFloat(data_Cheque) +
          parseFloat(data_Debitcard) +
          parseFloat(data_Creditcard) +
          parseFloat(data_Banktransfer) +
          parseFloat(data_Onlinepayment);

        let previous_remaining_amount =
          parseFloat(grand_total) - parseFloat(previous_total);

        toast?.error(`Total only ${grand_total}`);
      }
    }
  };

  const handleSubmit = (e) => {
    e?.preventDefault();

    const data = {
      status: true,
    };

    formdata(data);
  };

  const fetchData = () => {
    handleSearch("");
  };

  const barcodeInputRef = useRef("");

  const handleKeyDown = (event) => {
    const ignoredKeys = [
      "Shift",
      "Control",
      "Alt",
      "Meta",
      "ArrowUp",
      "ArrowDown",
      "ArrowLeft",
      "ArrowRight",
      "CapsLock",
    ];
    const validKey = /^[a-zA-Z0-9]$/;

    if (event.key === "Enter") {
      const barcode = barcodeInputRef.current.trim();

      if (barcode) {
        console.log(barcode, "barcodeInput");
        handleDescriptionBarcode(barcode);
      } else {
        console.warn("Empty barcode received");
      }

      barcodeInputRef.current = "";
    } else if (!ignoredKeys.includes(event.key) && validKey.test(event.key)) {
      barcodeInputRef.current += event.key;
    } else {
      console.warn(`Ignored key: ${event.key}`);
    }
  };

  const handleOnKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Modalcomponent
        open={open}
        setOpen={setOpen}
        modalData={modalData}
        setModalData={setModalData}
        selcectedModalData={selcectedModalData}
        setSelcectedModalData={setSelcectedModalData}
        handleModal={handleModal}
      />
      <form onSubmit={handleSubmit}>
        <div className="p-4">
          <div className="flex justify-between">
            {/* products */}
            <div className="w-[54%] rounded-md">
              <div className="p-4 flex justify-between bg-white rounded-tl-md rounded-tr-md">
                <div className="w-2/3 pr-8">
                  {/* <Select
                    className="w-full text-regular"
                    name="description"
                    placeholder="Description"
                    allowClear={true}
                    showSearch={true}
                    filterOption={false}
                    options={inventoryoption}
                    onSearch={(e) => handleSelectSearch(e)}
                    onFocus={(e) => handleSelectSearch("")}
                    onClick={(e) => handleSelectSearch("")}
                    onChange={(e) => handleDescription(e)}
                    autoComplete="off"
                  /> */}
                  <Input
                    allowClear
                    className="search "
                    placeholder=" Search"
                    onChange={(e) => handleSearch(e?.target?.value, "index")}
                    onKeyDown={handleOnKeyDown}
                    prefix={
                      <span className="text-gray-500 text-medium">
                        <SearchOutlined />
                      </span>
                    }
                  />
                </div>
                <div>
                  <BarcodeButton
                    tabIndex={0}
                    onKeyDown={handleKeyDown}
                    isFocused={isFocused}
                    setIsFocused={setIsFocused}
                  />
                </div>
              </div>
              <div className="bg-white h-[60vh]" style={{ overflowX: "auto" }}>
                <div className="flex flex-wrap">
                  {options?.map((value, index) => {
                    return (
                      <button
                        type="button"
                        key={index}
                        className="cursor-pointer p-3 w-[20%] hover:bg-gray-100 rounded-lg"
                        onClick={() => handleDescription(value?.value)}
                      >
                        <div>
                          <div className="">
                            <img
                              className="rounded-md w-full h-[100px]"
                              src={
                                value?.image
                                  ? `${URL}/uploads${value?.image}`
                                  : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6xiTpb6Tyc-CTn4FJmXyNBuPze14R-qIJNIDHj2uQbidXRFY1Otr27ZQd69L5_drFaDY&usqp=CAU"
                              }
                            />
                          </div>
                          <div className="text-center pt-2">
                            <div className="text-sm text-medium">
                              {value?.name}
                            </div>
                            <div className="text-xs text-gray-500">
                              {value?.number}
                              <div className="text-xs text-medium text-green-500">
                                Stock&nbsp;(
                                {value?.stock
                                  ? Number.isInteger(value?.stock)
                                    ? parseFloat?.(value?.stock || 0)
                                    : parseFloat?.(value?.stock || 0)?.toFixed(
                                        3
                                      )
                                  : "0"}
                                )
                              </div>
                            </div>
                            <div className="text-xs  text-gray-500">
                              {"EXP : " + value?.expiry_date}
                            </div>
                          </div>
                          <div className="text-center text-green-500 text-sm text-medium">
                            OMR {parseFloat?.(value?.sale_price)?.toFixed(3)}
                          </div>
                        </div>
                      </button>
                    );
                  })}
                </div>
              </div>
              {/* pagination */}
              <div className="p-4 flex justify-between text-gray-600 text-sm text-medium">
                <div>
                  <span className="pr-3">TOTAL</span>
                  <span className="pl-5">
                    1 - 10 OF {pagination?.totalCount}
                  </span>
                </div>
                <div className="flex">
                  <span>
                    {/* <button */}
                    <div
                      type="button"
                      className="px-4 py-1 rounded-md border-2 border-gray-300 hover:border-[#6254BE]"
                      onClick={handlePrevious}
                    >
                      <div className="text-center text-xs text-gray-900">
                        <LeftOutlined />
                      </div>
                    </div>
                    {/* </button> */}
                  </span>
                  <span className="px-3 pt-1">
                    {pagination?.currentPage} OUT OF {pagination?.totalPages}
                  </span>
                  <span>
                    {/* <button */}
                    <div
                      type="button"
                      className="px-4 py-1 rounded-md border-2 border-gray-300 hover:border-[#6254BE]"
                      onClick={handleNext}
                    >
                      <div className="text-center text-xs text-gray-600">
                        <RightOutlined />
                      </div>
                    </div>
                    {/* </button> */}
                  </span>
                </div>
              </div>
              {/* pagination end */}
              <div className="p-10 flex justify-between bg-white rounded-bl-md rounded-br-md" />
            </div>

            {/* checkout */}
            <div className="bg-white w-[44%] rounded-md">
              <div className="p-4 flex justify-center">
                <div className="p-1 text-semi-bold">CHECKOUT</div>
              </div>
              <div className="h-[50vh]" style={{ overflowY: "auto" }}>
                <table className="w-full">
                  <thead className="bg-gray-100 text-gray-500 text-sm text-medium">
                    <tr>
                      <td className=" p-1 border-r ">SNO</td>
                      <td className=" p-1 border-r">NAME</td>
                      <td className=" p-1 border-r">UNIT</td>
                      <td className=" p-1 border-r">QUANTITY</td>
                      {/* <td className=" p-1 border-r">DELIVERED</td> */}
                      <td className=" p-1 border-r">PRICE</td>
                      <td className=" p-1 border-r">TAX</td>
                      <td className=" p-1 border-r">TOTAL</td>
                      <td className=" p-1 text-center">DELETE</td>
                    </tr>
                  </thead>
                  <tbody>
                    {details?.map((value, index) => {
                      return (
                        <tr className=" border-b">
                          <td className="p-1 border-r">{index + 1}</td>
                          <td className="p-1 border-r  text-sm">
                            <div>{value?.name}</div>
                            <div>{`#BATCH-${value?.batch}`}</div>
                            <div>
                              {value?.expiry_date
                                ? `EXPIRY : ${moment(
                                    value?.expiry_date
                                  )?.format("DD-MM-YYYY")}`
                                : ""}
                            </div>
                          </td>
                          <td className="p-1 border-r">{value?.unit_name}</td>
                          <td className="p-1 border-r">
                            <div className="text-green-500 text-sm">
                              Stock&nbsp;(
                              {value?.stock
                                ? Number.isInteger(value?.stock)
                                  ? parseFloat?.(value?.stock || 0)
                                  : parseFloat?.(value?.stock || 0)?.toFixed(3)
                                : "0"}
                              )
                            </div>
                            <Input
                              type="number"
                              className="w-[100px]"
                              name="quantity"
                              placeholder="Quantity"
                              value={value?.quantity}
                              onChange={(e) => handleTotal(e, index)}
                              onKeyDown={handleOnKeyDown}
                              autoComplete="off"
                              required="required"
                            />
                          </td>
                          <td className="p-1 text-center border-r">
                            {value?.sale_price
                              ? value?.sale_price?.toFixed?.(3)
                              : "0.000"}
                          </td>
                          <td className="p-1 text-center border-r">
                            <div>{value?.tax}%</div>
                            <div>
                              {value?.tax_amount
                                ? value?.tax_amount?.toFixed?.(3)
                                : ""}
                            </div>
                          </td>
                          <td className="p-1 text-center border-r">
                            {value?.total
                              ? value?.total?.toFixed?.(3)
                              : "0.000"}
                          </td>
                          <td className="p-1 text-center border-r">
                            <span
                              className="cursor-pointer text-xl text-red-500 hover:text-red-900"
                              onClick={() => handleRemove(index)}
                            >
                              <DeleteOutlined />
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="bg-gray-100 text-medium text-gray-500">
                <div className="px-4 py-1 flex justify-between">
                  <div>Subtotal</div>
                  <div>
                    {totals?.subtotal > 0
                      ? totals?.subtotal?.toFixed(3)
                      : "0.000"}
                  </div>
                </div>
                <div className="px-4 py-1 flex justify-between">
                  <div>Tax amount</div>
                  <div>
                    {totals?.taxamount > 0
                      ? totals?.taxamount?.toFixed(3)
                      : "0.000"}
                  </div>
                </div>
                <div className="px-4 pb-2 flex justify-between">
                  <div className="my-auto">Discount</div>
                  <div>
                    <Input
                      type="number"
                      className="w-full text-regular"
                      placeholder="Discount amount"
                      name="discount"
                      value={totals?.discount}
                      autoComplete="off"
                      onChange={handleDiscount}
                    />
                  </div>
                </div>
                <div className="px-4 pb-2 flex justify-between">
                  <div className="my-auto">Delivery</div>
                  <div>
                    <Input
                      type="number"
                      className="w-full text-regular"
                      placeholder="Delivery charges"
                      name="delivery"
                      value={totals?.delivery}
                      autoComplete="off"
                      onChange={handleDelivery}
                    />
                  </div>
                </div>
              </div>
              <div className="bg-white pt-2 text-semi-bold">
                <div className="px-4 py-1 flex justify-between">
                  <div>Total</div>
                  <div>
                    {totals?.total > 0 ? totals?.total?.toFixed(3) : "0.000"}
                  </div>
                </div>
              </div>

              {/* info */}
              <div className="p-4 bg-gray-200">
                <div>
                  <div>
                    <Select
                      className="w-full text-regular"
                      name="customer"
                      showSearch
                      allowClear
                      placeholder="Select customer"
                      filterOption={false}
                      onSearch={(e) => handleCustomerSearch(e)}
                      onClick={(e) => handleCustomerSearch("")}
                      onFocus={(e) => handleCustomerSearch("")}
                      onChange={(e) => setCustomer(e)}
                      options={customerOptions}
                    />
                  </div>
                  <div className="flex w-full pt-4">
                    <div className="w-full">
                      <Input
                        className="w-full text-regular"
                        name="name"
                        value={customerName}
                        placeholder="New customer name"
                        onChange={handleCustomer}
                        autoComplete="off"
                      />
                    </div>
                    <div className="w-full pl-2">
                      <Input
                        className="w-full text-regular"
                        name="phone"
                        value={customerPhone}
                        placeholder="New customer phone"
                        onChange={handleCustomer}
                        autoComplete="off"
                      />
                    </div>
                    <div>
                      <Button
                        type="button"
                        className="w-full text-medium text-white bg-[#6254BE] hover:bg-[#14532d] mx-1"
                        onClick={addCustomer}
                      >
                        ADD
                      </Button>
                    </div>
                  </div>
                </div>

                {/* <div className="pt-4">
                  <div>
                    <Select
                      className="w-full"
                      name="tax"
                      placeholder="Delivery status"
                      value={totals?.delivery_status}
                      options={[
                        {
                          value: 0,
                          label: "Pending",
                        },
                        {
                          value: 1,
                          label: "Partial",
                        },
                        {
                          value: 2,
                          label: "Delivered",
                        },
                      ]}
                      onChange={handleDeliveryStatus}
                    />
                  </div>
                  {totals?.delivery_status == 2 && (
                    <div className="pt-4">
                      <DatePicker
                        type="number"
                        className="w-full"
                        placeholder="Delivered date"
                        name="delivery"
                        value={
                          totals?.delivery_date && dayjs(totals?.delivery_date)
                        }
                        onChange={handleDeliveryDate}
                        autoComplete="off"
                      />
                    </div>
                  )}
                </div> */}

                <div className="pt-4">
                  {/* <div>
                    <Select
                      className="w-full"
                      name="tax"
                      placeholder="Payment status"
                      value={totals?.payment_status}
                      options={[
                        {
                          value: 0,
                          label: "Unpaid",
                        },
                        {
                          value: 1,
                          label: "Partial",
                        },
                        {
                          value: 2,
                          label: "Paid",
                        },
                      ]}
                      onChange={handlePaymentStatus}
                    />
                  </div> */}

                  {totals?.payment_status == 1 ||
                  totals?.payment_status == 2 ? (
                    <div className="">
                      <Select
                        mode="multiple"
                        className="w-full"
                        name="tax"
                        placeholder="Payment mode"
                        value={totals?.payment_types}
                        options={[
                          {
                            value: "Cash",
                            label: "Cash",
                          },

                          {
                            value: "Debit card",
                            label: "Debit card",
                          },
                          {
                            value: "Bank transfer",
                            label: "Bank transfer",
                          },
                          {
                            value: "Online payment",
                            label: "Online payment",
                          },
                          {
                            value: "Credit card",
                            label: "Credit card",
                          },
                          {
                            value: "Cheque",
                            label: "Cheque",
                          },
                        ]}
                        onChange={handlePaymentTypes}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {totals?.payment_types?.map((value, index) => {
                    return totals?.payment_types?.length > 1 ? (
                      <div className="pt-4">
                        <Input
                          type="number"
                          className="w-full"
                          placeholder={value}
                          name={value}
                          value={totals?.payments?.[value]?.amount}
                          onChange={handlePayments}
                          autoComplete="off"
                          required="required"
                        />
                      </div>
                    ) : (
                      ""
                    );
                  })}
                </div>
              </div>
              {/* info */}
              <div className="p-4">
                <Button
                  htmlType="submit"
                  type="primary"
                  size="large"
                  className="w-full text-medium bg-[#6254BE] hover:bg-[#14532d]"
                  disabled={buttonLoader}
                >
                  {buttonLoader ? (
                    <Spin
                      indicator={<LoadingOutlined spin />}
                      size="small"
                      className="w-full text-medium bg-[#6254BE]"
                    />
                  ) : (
                    <>
                      Confirm {" (OMR "}
                      {totals?.total > 0 ? totals?.total?.toFixed(3) : "0.000"}
                      {") "}
                    </>
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default BillingPos;
