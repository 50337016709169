import React, { useEffect, useState } from "react";
import Header from "../../../Componets/Header";
import { useLocation } from "react-router-dom";
import products from "../../../Data/products.json";
import units from "../../../Data/units.json";
import categories from "../../../Data/category.json";
import brands from "../../../Data/brands.json";
import toast from "react-hot-toast";
import Loadercomponent from "../../../Componets/Loader";
import { get_all_products_low_reports } from "../../../Controllers/Dashboard/Dashboard";

const ProductsLowStockDashboard = () => {
  let location = useLocation();

  const [loader, setLoader] = useState(false);
  const [body, setBody] = useState();
  const [total, setTotal] = useState();

  const headers = {
    name: "Products",
    components: [
      {
        type: "search",
        name: "search",
        placeholder: "Search",
        value: "",
      },
      {
        type: "select",
        name: "sort",
        placeholder: "Sort",
        allowClear: true,
        showSearch: false,
        filterOption: true,
        value: null,
        options: [
          { value: 0, label: "Name (A-Z)" },
          { value: 1, label: "Name (Z-A)" },
          { value: 2, label: "Stock (Low)" },
          { value: 3, label: "Stock (High)" },
        ],
      },
      {
        type: "filter",
        name: "unit",
        allowClear: true,
        showSearch: true,
        filterOption: false,
        placeholder: "Units",
        api: "get-all-product-units",
        value: null,
        options: null,
      },
      {
        type: "filter",
        name: "category",
        placeholder: "Category",
        allowClear: true,
        showSearch: true,
        filterOption: false,
        api: "get-all-product-categories",
        value: null,
        options: null,
      },
      {
        type: "filter",
        name: "brand",
        placeholder: "Brands",
        allowClear: true,
        showSearch: true,
        filterOption: false,
        api: "get-all-product-brands",
        value: null,
        options: null,
      },
      {
        type: "filter",
        name: "type",
        placeholder: "Type",
        value: null,
        allowClear: true,
        showSearch: false,
        filterOption: true,
        options: [
          { value: 0, label: "Non-Stockable" },
          { value: 1, label: "Stockable" },
        ],
      },
      {
        type: "filter",
        name: "status",
        placeholder: "Status",
        value: null,
        allowClear: true,
        showSearch: false,
        filterOption: true,
        options: [
          { value: 0, label: "Inactive" },
          { value: 1, label: "Active" },
        ],
      },
      {
        type: "filter",
        name: "branch",
        allowClear: true,
        showSearch: true,
        filterOption: false,
        placeholder: "Branch",
        api: "get-all-branches",
        value: null,
        options: null,
      },
    ],
  };

  const [header, setHeader] = useState(headers);

  const formdata = (data) => {
    fetchData(data);
  };

  const fetchData = async (tabledata) => {
    try {
      setLoader(true);

      const data = {
        search: tabledata?.search,
        sort: tabledata?.sort,
        unit: tabledata?.unit,
        category: tabledata?.category,
        brand: tabledata?.brand,
        type: tabledata?.type,
        status: tabledata?.status,
        branch: tabledata?.branch,
      };

      const products = await get_all_products_low_reports(data);
      if (products?.status) {
        setBody(products?.data?.data);
        setTotal(products?.data?.grand_total_inventory);
        setLoader(false);
      } else {
        toast?.error(products?.message);
        setLoader(false);
      }
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header headers={header} setHeaders={setHeader} formdata={formdata}>
      <Loadercomponent loader={loader}>
        <table className="w-full bg-white">
          <thead className="text-gray-500">
            <tr>
              <td className="border p-4">S.No</td>
              <td className="border p-4">Name</td>
              <td className="border p-4">Units </td>
              <td className="border p-4">Category</td>
              <td className="border p-4">Brands</td>
              <td className="border p-4">Branch</td>
              <td className="border p-4">Stock</td>
            </tr>
          </thead>
          <tbody>
            {body?.map((value, index) => {
              return (
                <tr>
                  <td className="border p-4">{index + 1}</td>
                  <td className="border p-4">{value?.name}</td>
                  <td className="border p-4">{value?.unit?.name}</td>
                  <td className="border p-4">{value?.category?.name}</td>
                  <td className="border p-4">{value?.brand?.name}</td>
                  <td className="border p-4">{value?.branch?.name}</td>
                  <td className="border p-4">
                    {value?.total_inventory
                      ? value?.total_inventory?.toFixed?.(3)
                      : "0.000"}
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <td className="border p-4"></td>
            <td className="border p-4"></td>
            <td className="border p-4"></td>
            <td className="border p-4"></td>
            <td className="border p-4"></td>
            <td className="border p-4">
              {total ? total?.toFixed?.(3) : "0.000"}
            </td>
          </tfoot>
        </table>
      </Loadercomponent>
    </Header>
  );
};

export default ProductsLowStockDashboard;
