import React, { useEffect, useState } from "react";
import Header from "../../../Componets/Header";
import { useLocation } from "react-router-dom";
import inventory from "../../../Data/inventory.json";
import products from "../../../Data/products.json";
import { get_all_inventories } from "../../../Controllers/Inventory/Batch";
import toast from "react-hot-toast";
import moment from "moment";
import Loadercomponent from "../../../Componets/Loader";
import { get_all_inventories_near_expiry_reports } from "../../../Controllers/Dashboard/Dashboard";

const InventoryNearExpiry = () => {
  let location = useLocation();

  const [loader, setLoader] = useState(false);
  const [body, setBody] = useState();

  const headers = {
    name: "Inventory batch",
    pagination: {
      currentPage: 1,
      totalCount: 0,
      totalPages: 0,
    },
    components: [
      {
        type: "search",
        name: "search",
        placeholder: "Search",
        value: "",
      },
      {
        type: "select",
        name: "sort",
        placeholder: "Sort",
        value: null,
        allowClear: true,
        options: [
          { value: "1", label: "Name (A-Z)" },
          { value: "2", label: "Name (Z-A)" },
          { value: "3", label: "Expiry (ASC)" },
          { value: "4", label: "Expiry (DES)" },
          { value: "5", label: "Purchase (Low)" },
          { value: "6", label: "Purchase (High)" },
          { value: "7", label: "Sale (Low)" },
          { value: "8", label: "Sale (High)" },
        ],
      },
      {
        type: "filter",
        name: "product",
        allowClear: true,
        showSearch: true,
        filterOption: false,
        placeholder: "Product",
        api: "get-all-products",
        value: null,
        options: null,
      },
      {
        type: "range",
        name: "date",
        value: {
          start: "",
          end: "",
        },
      },
    ],
  };

  const [header, setHeader] = useState(headers);

  const formdata = (data) => {
    fetchData(data);
  };

  const fetchData = async (tabledata) => {
    try {
      setLoader(true);

      const data = {
        search: tabledata?.search,
        sort: tabledata?.sort,
        unit: tabledata?.unit,
        category: tabledata?.category,
        brand: tabledata?.brand,
        type: tabledata?.type,
        status: tabledata?.status,
        page: tabledata?.currentPage,
      };

      const products = await get_all_inventories_near_expiry_reports(data);
      if (products?.status) {
        setBody(products?.data?.data);
        setLoader(false);
      } else {
        toast?.error(products?.message);
        setLoader(false);
      }
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header headers={header} setHeaders={setHeader} formdata={formdata}>
      <Loadercomponent loader={loader}>
        <table className="w-full bg-white">
          <thead className="text-gray-500">
            <tr>
              <td className="border p-4">S.No</td>
              <td className="border p-4">Name</td>
              <td className="border p-4">Batch</td>
              <td className="border p-4">Expiry</td>
              <td className="border p-4">Tax</td>
              <td className="border p-4">Stock</td>
              <td className="border p-4">Purchase</td>
              <td className="border p-4">Price/unit</td>
              <td className="border p-4">Sale</td>
            </tr>
          </thead>
          <tbody>
            {body?.map((value, index) => {
              return (
                <tr>
                  <td className="border p-4">{index + 1}</td>
                  <td className="border p-4">{value?.product?.name}</td>
                  <td className="border p-4">{value?.number}</td>
                  <td className="border p-4">
                    {value?.expiry_date
                      ? moment?.(value?.expiry_date)?.format("DD/MM/YYYY")
                      : ""}
                  </td>
                  <td className="border p-4">{value?.tax}</td>
                  <td className="border p-4">{value?.stock}</td>
                  <td className="border p-4">{value?.purchase_price}</td>
                  <td className="border p-4">{value?.price_per_unit}</td>
                  <td className="border p-4">{value?.sale_price}</td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <td className="border p-4"></td>
            <td className="border p-4"></td>
            <td className="border p-4"></td>
            <td className="border p-4"></td>
            <td className="border p-4"></td>
            <td className="border p-4">
              {body
                ?.reduce(
                  (acc, currentValue) =>
                    parseFloat(acc) + parseFloat(currentValue?.stock),
                  0
                )
                ?.toFixed(3)}
            </td>
            <td className="border p-4">
              {body
                ?.reduce(
                  (acc, currentValue) =>
                    parseFloat(acc) + parseFloat(currentValue?.purchase_price),
                  0
                )
                ?.toFixed(3)}
            </td>
            <td className="border p-4">
              {body
                ?.reduce(
                  (acc, currentValue) =>
                    parseFloat(acc) + parseFloat(currentValue?.price_per_unit),
                  0
                )
                ?.toFixed(3)}
            </td>
            <td className="border p-4">
              {body
                ?.reduce(
                  (acc, currentValue) =>
                    parseFloat(acc) + parseFloat(currentValue?.sale_price),
                  0
                )
                ?.toFixed(3)}
            </td>
          </tfoot>
        </table>
      </Loadercomponent>
    </Header>
  );
};

export default InventoryNearExpiry;
