import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddButton from "../../../Componets/Buttons/add";
import DotIcon from "../../../Assets/Svg/dot";
import { FormOutlined } from "@ant-design/icons";
import AreaChart from "../../../Componets/Areachart";
import Barchart from "../../../Componets/Barchart";
import SalesIcon from "../../../Assets/Svg/sales";
import PurchaseIcon from "../../../Assets/Svg/purchase";
import ProductIcon from "../../../Assets/Svg/products";
import InventoryIcon from "../../../Assets/Svg/inventory";
import Piechart from "../../../Componets/Piechart";
import billing from "../../../Data/billing.json";
import Header from "../../../Componets/Header";
import headers from "../../../Data/Tables/Dashboard/Dashboard/headers";
import { get_dashboard } from "../../../Controllers/Dashboard/Dashboard";
import moment from "moment";
import toast from "react-hot-toast";
import Loadercomponent from "../../../Componets/Loader";
import CustomerIcon from "../../../Assets/Svg/customer";

const Dashboard = () => {
  let navigate = useNavigate();

  const [header, setHeader] = useState(headers);

  const formdata = (data) => {
    fetchData(data);
  };

  const [loader, setLoader] = useState(false);
  const [body, setBody] = useState();

  const [cards, setCards] = useState([
    {
      icon: <ProductIcon width={40} height={40} />,
      label: "Total Products",
      value: "0",
      color: "bg-blue-600",
      textcolor: "text-white",
    },
    {
      icon: <ProductIcon width={40} height={40} />,
      label: "Low Stock",
      value: "0",
      color: "bg-orange-600",
      textcolor: "text-white",
    },
    {
      icon: <ProductIcon width={40} height={40} />,
      label: "Out Of Stock",
      value: "0",
      color: "bg-red-600",
      textcolor: "text-white",
    },
  ]);

  const [cards1, setCards1] = useState([
    {
      icon: <InventoryIcon width={40} height={40} />,
      label: "Total Inventories",
      value: "0",
      color: "bg-violet-600",
      textcolor: "text-white",
    },
    {
      icon: <InventoryIcon width={40} height={40} />,
      label: "Near Expiry",
      value: "0",
      color: "bg-amber-600",
      textcolor: "text-white",
    },
    {
      icon: <InventoryIcon width={40} height={40} />,
      label: "Expired",
      value: "0",
      color: "bg-pink-600",
      textcolor: "text-white",
    },
  ]);

  const [cards2, setCards2] = useState([
    {
      icon: <SalesIcon width={40} height={40} />,
      label: "Total Sales",
      value: "0.000",
      color: "bg-green-600",
      textcolor: "text-white",
    },
    {
      icon: <SalesIcon width={40} height={40} />,
      label: "Total Sales Return",
      value: "0.000",
      color: "bg-fuchsia-600",
      textcolor: "text-white",
    },
  ]);

  const [cards3, setCards3] = useState([
    {
      icon: <CustomerIcon width={40} height={40} />,
      label: "Customer Paid",
      value: "0.000",
      color: "bg-green-600",
      textcolor: "text-white",
    },
    {
      icon: <CustomerIcon width={40} height={40} />,
      label: "Paid To Customer",
      value: "0.000",
      color: "bg-fuchsia-600",
      textcolor: "text-white",
    },
  ]);

  const [cards4, setCards4] = useState([
    {
      icon: <CustomerIcon width={40} height={40} />,
      label: "Customer Payable",
      value: "0.000",
      color: "bg-green-600",
      textcolor: "text-white",
    },
    {
      icon: <CustomerIcon width={40} height={40} />,
      label: "Payable To Customer",
      value: "0.000",
      color: "bg-fuchsia-600",
      textcolor: "text-white",
    },
  ]);

  const handleData = (dashboard) => {};

  const fetchData = async (tabledata) => {
    try {
      setLoader(true);

      let data = {
        date: tabledata?.date ? tabledata?.date : new Date(),
      };

      const dashboard = await get_dashboard(data);

      if (dashboard?.status) {
        cards[0].value = dashboard?.data?.total_products
          ? parseFloat(dashboard?.data?.total_products)
          : "0";
        cards[1].value = dashboard?.data?.total_low_stock
          ? parseFloat(dashboard?.data?.total_low_stock)
          : "0";
        cards[2].value = dashboard?.data?.total_out_of_stock
          ? parseFloat(dashboard?.data?.total_out_of_stock)
          : "0";

        cards1[0].value = dashboard?.data?.total_inventories
          ? parseFloat(dashboard?.data?.total_inventories)
          : "0";
        cards1[1].value = dashboard?.data?.total_near_expiry
          ? parseFloat(dashboard?.data?.total_near_expiry)
          : "0";
        cards1[2].value = dashboard?.data?.total_expired
          ? parseFloat(dashboard?.data?.total_expired)
          : "0";

        cards2[0].value = {
          total_sales: dashboard?.data?.total_sales
            ? parseFloat(dashboard?.data?.total_sales)
            : 0,
          total_discount: dashboard?.data?.total_discount
            ? parseFloat(dashboard?.data?.total_discount)
            : 0,
          total_delivery: dashboard?.data?.total_delivery
            ? parseFloat(dashboard?.data?.total_delivery)
            : 0,
          total_cash: dashboard?.data?.total_cash
            ? parseFloat(dashboard?.data?.total_cash)
            : 0,
          total_debit_card: dashboard?.data?.total_debit_card
            ? parseFloat(dashboard?.data?.total_debit_card)
            : 0,
          total_credit_card: dashboard?.data?.total_credit_card
            ? parseFloat(dashboard?.data?.total_credit_card)
            : 0,
          total_bank_transfer: dashboard?.data?.total_bank_transfer
            ? parseFloat(dashboard?.data?.total_bank_transfer)
            : 0,
          total_online_payment: dashboard?.data?.total_online_payment
            ? parseFloat(dashboard?.data?.total_online_payment)
            : 0,
          total_cheque: dashboard?.data?.total_cheque
            ? parseFloat(dashboard?.data?.total_cheque)
            : 0,
        };
        cards2[1].value = {
          total_sales: dashboard?.data?.total_sales_returns_sales
            ? parseFloat(dashboard?.data?.total_sales_returns_sales)
            : 0,
          total_discount: dashboard?.data?.total_sales_returns_discount
            ? parseFloat(dashboard?.data?.total_sales_returns_discount)
            : 0,
          total_delivery: dashboard?.data?.total_sales_returns_delivery
            ? parseFloat(dashboard?.data?.total_sales_returns_delivery)
            : 0,
          total_cash: dashboard?.data?.total_sales_returns_cash
            ? parseFloat(dashboard?.data?.total_sales_returns_cash)
            : 0,
          total_debit_card: dashboard?.data?.total_sales_returns_debit_card
            ? parseFloat(dashboard?.data?.total_sales_returns_debit_card)
            : 0,
          total_credit_card: dashboard?.data?.total_sales_returns_credit_card
            ? parseFloat(dashboard?.data?.total_sales_returns_credit_card)
            : 0,
          total_bank_transfer: dashboard?.data
            ?.total_sales_returns_bank_transfer
            ? parseFloat(dashboard?.data?.total_sales_returns_bank_transfer)
            : 0,
          total_online_payment: dashboard?.data
            ?.total_sales_returns_online_payment
            ? parseFloat(dashboard?.data?.total_sales_returns_online_payment)
            : 0,
          total_cheque: dashboard?.data?.total_sales_returns_cheque
            ? parseFloat(dashboard?.data?.total_sales_returns_cheque)
            : 0,
        };

        cards3[0].value = dashboard?.data?.total_customer_paid
          ? parseFloat(dashboard?.data?.total_customer_paid)
          : 0;
        cards3[1].value = dashboard?.data?.total_sales_returns_customer_paid
          ? parseFloat(dashboard?.data?.total_sales_returns_customer_paid)
          : 0;

        cards4[0].value = dashboard?.data?.total_customer_payable
          ? parseFloat(dashboard?.data?.total_customer_payable)
          : 0;
        cards4[1].value = dashboard?.data?.total_sales_returns_customer_payable
          ? parseFloat(dashboard?.data?.total_sales_returns_customer_payable)
          : 0;

        setCards(cards);
        setCards1(cards1);
        setCards2(cards2);
        setCards3(cards3);
        setCards4(cards4);
        setBody(dashboard?.data);
        setLoader(false);
      }
    } catch (errors) {
      toast?.error(errors?.message);
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header headers={header} setHeaders={setHeader} formdata={formdata}>
      <Loadercomponent loader={loader}>
        <div className="flex justify-between">
          {cards?.map((value, index) => {
            return (
              <div className={`${value?.color} w-[32%] rounded-md p-6`}>
                <div className="flex justify-between">
                  <div>
                    <div
                      className={`${value?.textcolor} text-4xl text-semi-bold`}
                    >
                      {value?.value}
                    </div>
                    <div className="text-white text-lg pt-2">
                      {value?.label}
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div className={`${value?.textcolor} text-2xl`}>
                      {value?.icon}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="flex justify-between pt-6">
          {cards1?.map((value, index) => {
            return (
              <div className={`${value?.color} w-[32%] rounded-md p-6`}>
                <div className="flex justify-between">
                  <div>
                    <div
                      className={`${value?.textcolor} text-4xl text-semi-bold`}
                    >
                      {value?.value}
                    </div>
                    <div className="text-white text-lg pt-2">
                      {value?.label}
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div className={`${value?.textcolor} text-2xl`}>
                      {value?.icon}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="flex justify-between pt-6">
          {cards2?.map((value, index) => {
            return (
              <div className={`${value?.color} w-[48%] rounded-md p-6`}>
                <div>
                  <div className="flex justify-between">
                    <div>
                      <div
                        className={`${value?.textcolor} text-4xl text-semi-bold`}
                      >
                        {value?.value?.total_sales?.toFixed?.(3)}
                      </div>
                      <div className="text-white text-lg pt-2">
                        {value?.label}
                      </div>
                    </div>
                    <div className="flex items-center">
                      <div className={`${value?.textcolor} text-2xl`}>
                        {value?.icon}
                      </div>
                    </div>
                  </div>
                  <div className="pt-3">
                    <div className="flex justify-between">
                      <div className="text-white text-sm text-medium pt-2">
                        DISCOUNT
                      </div>
                      <div className="text-white text-sm text-medium pt-2">
                        {value?.value?.total_discount?.toFixed?.(3)}
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <div className="text-white text-sm text-medium pt-2">
                        DELIVERY
                      </div>
                      <div className="text-white text-sm text-medium pt-2">
                        {value?.value?.total_delivery?.toFixed?.(3)}
                      </div>
                    </div>
                  </div>
                  <div className="pt-3">
                    <div className="flex justify-between">
                      <div className="text-white text-sm text-medium pt-2">
                        CASH
                      </div>
                      <div className="text-white text-sm text-medium pt-2">
                        {value?.value?.total_cash?.toFixed?.(3)}
                      </div>
                    </div>

                    <div className="flex justify-between">
                      <div className="text-white text-sm text-medium pt-2">
                        DEBIT CARD
                      </div>
                      <div className="text-white text-sm text-medium pt-2">
                        {value?.value?.total_debit_card?.toFixed?.(3)}
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <div className="text-white text-sm text-medium pt-2">
                        CREDIT CARD
                      </div>
                      <div className="text-white text-sm  text-medium pt-2">
                        {value?.value?.total_credit_card?.toFixed?.(3)}
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <div className="text-white text-sm text-medium pt-2">
                        BANK TRANSFER
                      </div>
                      <div className="text-white text-sm text-medium pt-2">
                        {value?.value?.total_bank_transfer?.toFixed?.(3)}
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <div className="text-white text-sm text-medium pt-2">
                        ONLINE PAYMENT
                      </div>
                      <div className="text-white text-sm text-medium pt-2">
                        {value?.value?.total_online_payment?.toFixed?.(3)}
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <div className="text-white text-sm text-medium pt-2">
                        CHEQUE
                      </div>
                      <div className="text-white text-sm text-medium pt-2">
                        {value?.value?.total_cheque?.toFixed?.(3)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="flex justify-between pt-6">
          {cards3?.map((value, index) => {
            return (
              <div className={`${value?.color} w-[48%] rounded-md p-6`}>
                <div>
                  <div className="flex justify-between">
                    <div>
                      <div
                        className={`${value?.textcolor} text-4xl text-semi-bold`}
                      >
                        {value?.value?.toFixed?.(3)}
                      </div>
                      <div className="text-white text-lg pt-2">
                        {value?.label}
                      </div>
                    </div>
                    <div className="flex items-center">
                      <div className={`${value?.textcolor} text-2xl`}>
                        {value?.icon}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="flex justify-between pt-6">
          {cards4?.map((value, index) => {
            return (
              <div className={`${value?.color} w-[48%] rounded-md p-6`}>
                <div>
                  <div className="flex justify-between">
                    <div>
                      <div
                        className={`${value?.textcolor} text-4xl text-semi-bold`}
                      >
                        {value?.value?.toFixed?.(3)}
                      </div>
                      <div className="text-white text-lg pt-2">
                        {value?.label}
                      </div>
                    </div>
                    <div className="flex items-center">
                      <div className={`${value?.textcolor} text-2xl`}>
                        {value?.icon}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="pt-8 pb-4">
          <div className="">
            <table className="w-full rounded-lg overflow-hidden">
              <thead>
                <tr className="text-white bg-black border-b">
                  <td className="p-3 first:rounded-tl-lg last:rounded-tr-lg">
                    Invoice
                  </td>
                  <td className="p-3">Date</td>
                  <td className="p-3">Customer</td>
                  <td className="p-3">Created</td>
                  <td className="p-3 text-right first:rounded-tl-lg last:rounded-tr-lg">
                    Amount
                  </td>
                </tr>
              </thead>
              <tbody>
                {body?.recent_invoices?.map(
                  (value, index) =>
                    index <= 5 && (
                      <tr
                        className={`hover:bg-gray-200 bg-white border-b ${
                          index === billing?.length - 1
                            ? "last:rounded-bl-lg last:rounded-br-lg border-none"
                            : ""
                        }`}
                        key={index}
                      >
                        <td className="p-3">{`#INV-${value?.number}`}</td>
                        <td className="p-3">
                          {moment?.(value?.date)?.format?.("DD-MM-YYYY")}
                        </td>
                        <td className="p-3">
                          {value?.customer?.name
                            ? value?.customer?.name
                            : "Walk-in-customer"}
                        </td>
                        <td className="p-3">
                          {value?.created_by?.name
                            ? value?.created_by?.name
                            : ""}
                        </td>
                        <td className="p-3 text-right">
                          {parseFloat(value?.total)?.toFixed(3)}
                        </td>
                      </tr>
                    )
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="pt-8 pb-4">
          <div className="">
            <table className="w-full rounded-lg overflow-hidden">
              <thead>
                <tr className="text-white bg-black border-b">
                  <td className="p-3 first:rounded-tl-lg last:rounded-tr-lg">
                    Rquested
                  </td>
                  <td className="p-3">Date</td>
                  <td className="p-3">Branch</td>
                  <td className="p-3">Created</td>
                  <td className="p-3 text-right first:rounded-tl-lg last:rounded-tr-lg">
                    Status
                  </td>
                </tr>
              </thead>
              <tbody>
                {body?.inventory_requestes?.map(
                  (value, index) =>
                    index <= 5 && (
                      <tr
                        className={`hover:bg-gray-200 bg-white border-b ${
                          index === billing?.length - 1
                            ? "last:rounded-bl-lg last:rounded-br-lg border-none"
                            : ""
                        }`}
                        key={index}
                      >
                        <td className="p-3">{`#REQUESTED-${value?.number}`}</td>
                        <td className="p-3">
                          {moment?.(value?.date)?.format?.("DD-MM-YYYY")}
                        </td>
                        <td className="p-3">
                          {value?.branch?.name ? value?.branch?.name : ""}
                        </td>
                        <td className="p-3">
                          {value?.created_by?.name
                            ? value?.created_by?.name
                            : ""}
                        </td>
                        <td className="p-3 text-right">
                          {value?.status ? "Accepted" : "Pending"}
                        </td>
                      </tr>
                    )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Loadercomponent>
    </Header>
  );
};

export default Dashboard;
