import taxes from "../../../taxes.json";

const forms = [
  {
    name: "Inventory",
    options: [
      {
        name: "Batch number",
        type: "input",
        form: {
          type: "text",
          name: "number",
          placeholder: "Batch number",
          class: "",
          value: "",
          required: false,
        },
      },
      {
        name: "Product",
        type: "selectandadd",
        path: "/app/products/add",
        form: {
          name: "product",
          allowClear: true,
          showSearch: true,
          filterOption: false,
          placeholder: "Product",
          api: "get-all-products",
          options: null,
          value: null,
          required: true,
        },
      },
      {
        name: "Barcode",
        type: "input",
        form: {
          type: "text",
          name: "barcode",
          placeholder: "Batch barcode",
          class: "",
          value: "",
          required: false,
        },
      },
      {
        name: "Supplier",
        type: "selectandadd",
        path: "/app/suppliers/add",
        form: {
          name: "supplier",
          allowClear: true,
          showSearch: true,
          filterOption: false,
          placeholder: "Supplier",
          api: "get-all-suppliers",
          options: null,
          value: null,
          required: false,
        },
      },
    ],
  },
  {
    name: "Pricing",
    options: [
      {
        name: "Purchase price",
        type: "input",
        form: {
          type: "number",
          name: "purchase_price",
          placeholder: "Batch purchase price",
          value: "",
          required: true,
        },
      },
      {
        name: "Price/unit",
        type: "input",
        form: {
          type: "number",
          name: "price_per_unit",
          placeholder: "Price per unit",
          value: "",
          required: true,
        },
      },
      {
        name: "Sale price",
        type: "input",
        form: {
          type: "number",
          name: "sale_price",
          placeholder: "Batch sale price",
          value: "",
          required: true,
        },
      },
      {
        name: "Tax",
        type: "select",
        form: {
          name: "tax",
          allowClear: false,
          placeholder: "Tax",
          value: 0,
          options: taxes?.map((value, index) => ({
            value: value?.value,
            label: value?.label,
          })),
          required: false,
        },
      },
    ],
  },
  {
    name: "Stock",
    options: [
      {
        name: "Stock",
        type: "input",
        form: {
          type: "number",
          name: "stock",
          placeholder: "Batch stock",
          class: "",
          value: "",
          required: false,
        },
      },
    ],
  },
  {
    name: "Date",
    options: [
      {
        name: "Manufacture Date",
        type: "date",
        form: {
          type: "text",
          name: "manufacture_date",
          placeholder: "Batch manufacture date",
          class: "",
          value: "",
          required: false,
        },
      },
      {
        name: "Expiry Date",
        type: "date",
        form: {
          type: "text",
          name: "expiry_date",
          placeholder: "Batch expiry date",
          class: "",
          value: "",
          required: false,
        },
      },
    ],
  },
  {
    name: "Status",
    options: [
      {
        name: "Status",
        type: "select",
        form: {
          name: "status",
          allowClear: false,
          placeholder: "Status",
          value: 1,
          options: [
            {
              value: 1,
              label: "Active",
            },
            {
              value: 0,
              label: "In-active",
            },
          ],
          required: false,
        },
      },
    ],
  },
];

export default forms;
